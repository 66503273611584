import iconAreaVerde01 from './icon-area-verde-01.svg';
import iconAreaVerde02 from './icon-area-verde-02.svg';
import iconAreaVerde03 from './icon-area-verde-03.svg';
import iconArmas from './icon-armas.svg';
import iconCampamentos from './icon-campamentos.svg';
import iconCentroSalud from './icon-centro-salud.svg';
import iconCiclovia from './icon-ciclovia.svg';
import iconInscritosFonasa from './icon-inscritos-fonasa.svg';
import iconPaes from './icon-paes.svg';
import iconPuntoLimpio from './icon-punto-limpio.svg';
import iconTransparenciaActiva from './icon-transparencia-activa.svg';
import iconHabitantes from './icon-habitantes.svg';
import iconSuperficie from './icon-superficie.svg';
import iconAdultoMayor from './icon-adulto-mayor.svg';
import iconPoblacionRural from './icon-poblacion-rural.svg';
import iconMetasIapps from './icon-metas-iapps.svg';

export const icons = {
  'icon-area-verde-01': iconAreaVerde01,
  'icon-area-verde-02': iconAreaVerde02,
  'icon-area-verde-03': iconAreaVerde03,
  'icon-armas': iconArmas,
  'icon-campamentos': iconCampamentos,
  'icon-centro-salud': iconCentroSalud,
  'icon-ciclovia': iconCiclovia,
  'icon-inscritos-fonasa': iconInscritosFonasa,
  'icon-paes': iconPaes,
  'icon-punto-limpio': iconPuntoLimpio,
  'icon-transparencia-activa': iconTransparenciaActiva,
  'icon-habitantes': iconHabitantes,
  'icon-superficie': iconSuperficie,
  'icon-adulto-mayor': iconAdultoMayor,
  'icon-poblacion-rural': iconPoblacionRural,
  'icon-metas-iapps': iconMetasIapps,
};
