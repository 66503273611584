// Credits.js
import React from 'react';

const Credits = ({ text }) => {
    return (
        
        <div class="fuente-box">
            <p class="fuente-txt"> 
                <b class="title-fuente">Equipo editorial</b>
            </p>
			<p class="fuente-txt"> 
                Edición y Producción Periodística: Paz Fernández.
            </p>
            <p class="fuente-txt"> 
                Edición General de 24horas.cl: Diego Valdés Florenzano.
            </p>
            <p class="fuente-txt"> 
                Diseño: Natalia García.
            </p>
            <p class="fuente-txt"> 
                Desarrollo: Belén Toro, Francisca Muñoz, José Pino.
            </p>
		</div>
    );
};

export default Credits;
