// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;  /* Para ocupar toda la pantalla */
    flex-direction: column;
  }
  
  .spinner {
    border: 8px solid #f3f3f3;  /* Color del borde */
    border-top: 8px solid #3498db;  /* Color del borde superior */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;  /* Animación de giro */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  p {
    margin-top: 20px;
    font-size: 18px;
    color: #555;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/LoadingSpinner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,GAAG,iCAAiC;IACjD,sBAAsB;EACxB;;EAEA;IACE,yBAAyB,GAAG,oBAAoB;IAChD,6BAA6B,GAAG,6BAA6B;IAC7D,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC,GAAG,sBAAsB;EAC7D;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW;EACb","sourcesContent":[".loading-spinner-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;  /* Para ocupar toda la pantalla */\n    flex-direction: column;\n  }\n  \n  .spinner {\n    border: 8px solid #f3f3f3;  /* Color del borde */\n    border-top: 8px solid #3498db;  /* Color del borde superior */\n    border-radius: 50%;\n    width: 60px;\n    height: 60px;\n    animation: spin 1s linear infinite;  /* Animación de giro */\n  }\n  \n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  \n  p {\n    margin-top: 20px;\n    font-size: 18px;\n    color: #555;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
