import React from 'react';
import Search from './Search';
import logo from '../assets/img/chile-elige-2024.svg';
import datatvn from '../assets/img/24-data-en-tu-comuna.svg';
import { Link } from "react-router-dom";
import Credits from './Credits';

const HomePage = () => {
    
    const handleSearchValueChange = (value) => {
        console.log('Search value changed:', value);
        // Aquí puedes manejar el valor de búsqueda como necesites
    };
    
    return (
        <section>
            <div className="content-box">
                <div className="box-logo">
                    <Link to="/">
                        <img src={logo} alt="Logo Chile Elige 2024" />
                    </Link>
                </div>
                <div className="box-txto">
                    <img className="box-datatvn" src={datatvn}  alt="Logo TVN Data en tu comuna" />
                    <h4 className="txto-ppal">
                        El próximo <b className="bold-txt">sábado 26 y domingo 27 de octubre</b>&nbsp;
                        se realizarán las elecciones de <b className="bold-txt">Gobernadores Regionales, Consejeros Regionales, Alcaldes y Concejales.</b> 
                        &nbsp;Los chilenos y chilenas deberán acudir a las urnas en el marco de las elecciones –de carácter obligatorio–. 
                        En ese contexto, <a href="https://www.24horas.cl" target="_blank" rel="noopener noreferrer">24horas.cl</a> desarrolló esta plataforma para ver la realidad de tu comuna con distintos indicadores que te permitirán votar informado.
                    </h4>
                </div>
                <Search className="search-box" onSearchValueChange={handleSearchValueChange} />
                <hr />
                <Credits></Credits>
            </div>
        </section>
    );
};

export default HomePage;
