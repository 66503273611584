import { useEffect, useState } from "react";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";  // Importa el spinner

const DataFetcher = ({ apiUrl, children }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Error al obtener los datos de la API');
                }
                const result = await response.json();
                setData(result);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    // Mostrar el spinner mientras se cargan los datos
    if (loading) {
        return <LoadingSpinner />;
    }

    // Mostrar error si hay uno
    if (error) {
        return <p>Error: {error}</p>;
    }

    // Pasar los datos, loading y error como props a los hijos
    return children({ data, loading, error });
};

export default DataFetcher;
