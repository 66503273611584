import React from 'react';

const ComunaHeader = ({ comunaName, comunaRegion }) => {
    // Eliminar "Región ", "Región de " o "de " al inicio del string
    const cleanRegion = comunaRegion.replace(/^(Región(\s+de)?|de)\s+/, '');

    return (
      <div className="title-box">
        <div className="nombre-comuna">
          <h3 className="title-comuna">{comunaName}</h3>
        </div>
        <div className="region-elegida">
          <h4 className="subtitle-region">
            <b>Región:</b> {cleanRegion}
          </h4>
        </div>
      </div>
    );
};

export default ComunaHeader;
