// FuenteBox.js
import React from 'react';

const FuenteBox = ({ textoFuente, small }) => {
    const fuenteBoxStyle = {
        marginTop: '10px',
        textAlign: 'left',
    };

    const fuenteTxtStyle = {
        fontSize: small ? '0.8em' : '1em',
        margin: '0',
    };

    const titleFuenteStyle = {
        fontWeight: 'bold',
    };

    return (
        <div style={fuenteBoxStyle}>
            <p style={fuenteTxtStyle}>
                <b style={titleFuenteStyle}>Fuente:</b> {textoFuente}
            </p>
        </div>
    );
};

export default FuenteBox;
