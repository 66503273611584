// MedioAmbienteView.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import Menu from "./Menu";
import HeaderComuna from "./HeaderComuna";
import { useComuna } from "./ComunaContext";
import DataFetcher from "./DataFetcher";
import IconComponent from "./IconComponent";
import ChartWrapper from "./charts/ChartWrapper";
import ComunaHeader from './ComunaHeader';
import { formatComunaName } from '../utils/utils';
import FuenteBox from "./Fuente"; 
import Credits from './Credits';

const MedioAmbienteView = () => {
    const { slug } = useParams();
    const { comunaData, fetchComunaData, comunaName, setComunaName, comunaRegion, setComunaRegion, comunasOptions } = useComuna();
    const [loading, setLoading] = useState(true);
    const [prevSlug, setPrevSlug] = useState('');
    const apiUrlMedioAmbiente = `${process.env.REACT_APP_API_URL}/medio-ambiente-por-comuna/${slug}`;

    const parsePercentage = (value) => {
        return parseFloat(value.replace(',', '.').replace('%', ''));
    };

    useEffect(() => {
        if (slug !== prevSlug) {
            const selectedComuna = comunasOptions.find((c) => c.slug === slug);
            if (selectedComuna) {
                setComunaName(selectedComuna.label);
                setComunaRegion(selectedComuna.region);
                setPrevSlug(slug);
                setLoading(true);
                fetchComunaData(slug).then(() => {
                    setLoading(false);
                });
            }
        }
    }, [slug, prevSlug, setComunaName, setComunaRegion, fetchComunaData, comunasOptions]);

    if (loading || !comunaData) {
        return null;
    }

    return (
        <DataFetcher apiUrl={apiUrlMedioAmbiente}>
            {({ data: medioAmbienteData, loading, error }) => {
                if (loading) return null;
                if (error) return <p>Error: {error}</p>;
                if (!medioAmbienteData) return <p>No hay datos disponibles</p>;

                const comunaMasAltaPorcentaje = parsePercentage(medioAmbienteData.inseguridadAlimentariaPais.comunaMasAlta.porcentaje);
                const comunaMasBajaPorcentaje = parsePercentage(medioAmbienteData.inseguridadAlimentariaPais.comunaMasBaja.porcentaje);
                const promedioNacional = parsePercentage(medioAmbienteData.inseguridadAlimentariaPais.promedioNacional);
                const comunaPorcentaje = parsePercentage(medioAmbienteData.inseguridadAlimentaria.porcentajeInseguridadAlimentaria.toString());

                const labels = [
                    `Alto Biobio (${comunaMasAltaPorcentaje}%)`,
                    `Promedio Nacional (${promedioNacional}%)`,
                    `Torres Del Paine (${comunaMasBajaPorcentaje}%)`,
                    `${formatComunaName(comunaName)} (${comunaPorcentaje}%)`
                ];

                // Validación para kilómetros de ciclovías con chequeo de existencia de `ciclovias`
                const kilometrosCiclovias = medioAmbienteData.ciclovias?.kilometrosCiclovias || 0;
                
                return (
                    <div>
                        <HeaderSearch />
                        <HeaderComuna />
                        <Menu />

                        <section id="tema-elegido">
                            <div className="content-box" id="perfil-comunal">
                                <ComunaHeader comunaName={comunaName} comunaRegion={comunaRegion} />
                                
                                <div className="info-box">
                                    {/* Condición para mostrar la sección de Áreas verdes por habitante */}
                                    {medioAmbienteData.areasVerdes.areasVerdesPorHabitante !== "No Recepcionado" && (
                                        <div className="info-variable">
                                            <h3 className="title-info-variable">Metros cuadrados de áreas verdes con mantenimiento por habitante</h3>
                                            <IconComponent 
                                                size="100px"
                                                iconName="icon-area-verde-01" 
                                                value={medioAmbienteData.areasVerdesPais.comunaConMenosAreasVerdes.hectareasPorHabitante}
                                                subtitle={medioAmbienteData.areasVerdesPais.comunaConMenosAreasVerdes.nombre}
                                            />
                                            <IconComponent 
                                                size="100px"
                                                iconName="icon-area-verde-02" 
                                                value={medioAmbienteData.areasVerdes.areasVerdesPorHabitante} 
                                                subtitle={formatComunaName(comunaName)}
                                            />
                                            <IconComponent 
                                                size="100px"
                                                iconName="icon-area-verde-03" 
                                                value={medioAmbienteData.areasVerdesPais.comunaConMasAreasVerdes.hectareasPorHabitante} 
                                                subtitle={medioAmbienteData.areasVerdesPais.comunaConMasAreasVerdes.nombre}
                                            />
                                            <FuenteBox
                                                textoFuente="Sistema Nacional de Información Municipal. "
                                                small
                                            />
                                        </div>
                                    )}

                                    <div className="info-variable">
                                        <div className="grafico-box">
                                            <h3 className="title-info-variable">Puntos limpios</h3>
                                            <IconComponent
                                                iconName="icon-punto-limpio"
                                                value={
                                                    medioAmbienteData && medioAmbienteData.puntosVerdes && !isNaN(Number(medioAmbienteData.puntosVerdes.cantidadPuntosLimpios)) 
                                                    ? medioAmbienteData.puntosVerdes.cantidadPuntosLimpios 
                                                    : 0
                                                }
                                                subtitle="Puntos limpios en la comuna"
                                            />
                                            <FuenteBox
                                                textoFuente="Subsecretaría del Medio Ambiente vía solicitud de transparencia. Los puntos limpios son de distintos tipos de dueños, incluyendo propiedad municipal o de privados."
                                                small
                                            />
                                        </div>
                                        <div className="grafico-box">
                                            <h3 className="title-info-variable">Ciclovías</h3>
                                            <IconComponent
                                                iconName="icon-ciclovia"
                                                value={kilometrosCiclovias}
                                                subtitle="Ciclovías en kilómetros"
                                            />
                                            <FuenteBox
                                                textoFuente="Ministerio de Bienes Nacionales."
                                                small
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="info-box">
                                    <div className="info-variable">
                                        <h3 className="title-info-variable">Inseguridad Alimentaria</h3>
                                        <ChartWrapper
                                            chartType="bar"
                                            labels={labels}
                                            datasets={[{
                                                label: 'Porcentaje de Inseguridad Alimentaria',
                                                data: [
                                                    comunaMasAltaPorcentaje,
                                                    promedioNacional,
                                                    comunaMasBajaPorcentaje,
                                                    comunaPorcentaje
                                                ]
                                            }]}
                                            color="verde"
                                        />
                                        <FuenteBox
                                            textoFuente="Encuesta Casen 2022. Según la FAO el concepto de inseguridad alimentaria se refiere a la falta de acceso físico, social y/o económico a alimentos suficientes, inocuos y nutritivos que permitan a las personas satisfacer sus necesidades y llevar una vida activa y sana."
                                            small
                                        />
                                    </div>
                                </div>
                                <hr />
                    <Credits></Credits>
                            </div>
                        </section>
                    </div>
                );
            }}
        </DataFetcher>
    );
};

export default MedioAmbienteView;
