import React from "react";
import { Link, useLocation } from 'react-router-dom';
import { useComuna } from './ComunaContext';

const Menu = () => {
    const { comunaSlug } = useComuna(); 
    const location = useLocation();

    const routes = [
        { path: `/${comunaSlug}`, label: 'perfil comunal' },
        { path: `/${comunaSlug}/seguridad`, label: 'seguridad' },
        { path: `/${comunaSlug}/educacion`, label: 'educación' },
        { path: `/${comunaSlug}/salud`, label: 'salud' },
        { path: `/${comunaSlug}/pobreza`, label: 'pobreza' },
        { path: `/${comunaSlug}/medio-ambiente`, label: 'medio ambiente' },
    ];
    
    return (
        <section>
            <div className="content-box">
                <div className="scrollmenu">
                    {routes.map(({ path, label }) => (
                        <Link 
                            key={path}
                            to={path} 
                            className={`link-tema ${location.pathname === path ? 'select-link' : ''}`}
                        >
                            {label}
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Menu;
