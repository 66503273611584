// Footnote.js
import React from 'react';

const Footnote = ({ number, text }) => {
    return (
        <p style={{ fontSize: '0.8em', margin: '0.5em 0' }}>
            <sup>({number})</sup> {text}
        </p>
    );
};

export default Footnote;
