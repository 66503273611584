// IconComponent.js
import React from 'react';
import { icons } from '../assets/img/svg-icons/svgIcons';  // Ruta hacia tu archivo de íconos

const IconComponent = ({ iconName, value, subtitle, size, width, height }) => {
  const SvgIcon = icons[iconName];  // Buscar el ícono correcto según iconName

  if (!SvgIcon) {
    return <span>Icono no encontrado</span>;  // Manejo de errores si no se encuentra el ícono
  }

  // Determinar el tamaño del icono
  const iconStyle = {};
  if (size) {
    iconStyle.width = size;
    iconStyle.height = size;
  } else {
    if (width) iconStyle.width = width;
    if (height) iconStyle.height = height;
  }

  return (
    <div className="icon-box">
      <img
        src={SvgIcon}
        alt={iconName}
        className="icon habit-icon"
        style={iconStyle}
      />
      <h2 className="title-info">
        {value !== undefined && value !== null ? value.toLocaleString('es-ES') : ''}
      </h2>
      <span className="subtitle-info">{subtitle}</span>
    </div>
  );
};

export default IconComponent;
