import React, { createContext, useState, useContext, useEffect } from 'react';
import comunas from '../assets/comunas.json';

const ComunaContext = createContext();

export const ComunaProvider = ({ children }) => {
    const [comunaData, setComunaData] = useState(null);
    const [comunaName, setComunaName] = useState('');
    const [comunaSlug, setComunaSlug] = useState('');
    const [comunaRegion, setComunaRegion] = useState(''); // Estado para la región
    const [comunasOptions, setComunasOptions] = useState([]);

    // Función para obtener el primer nombre antes del carácter "|"
    const getFormalName = (name) => {
        const [formalName] = name.split('|');
        return formalName.trim();
    };

    // CARGAR COMUNAS
    useEffect(() => {
        const comunasArray = comunas.map(comuna => ({
            label: getFormalName(comuna.nombre),
            value: comuna.id,
            slug: comuna.slug,
            region: comuna.region, // Asegurarse de que la región esté aquí si viene en el JSON
        }));
        setComunasOptions(comunasArray);
    }, []);

    // ACTUALIZAR DATOS COMUNAS
    const fetchComunaData = async (slug) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/perfil-comunal/${slug}`);
            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.status}`);
            }
            const data = await response.json();
            setComunaData(data);
            setComunaSlug(slug);
        } catch (error) {
            console.error('Error fetching comuna data:', error);
        }
    };

    return (
        <ComunaContext.Provider value={{
            comunaData,
            fetchComunaData,
            comunaName,
            setComunaName,
            comunaSlug,
            setComunaSlug,
            comunaRegion,
            setComunaRegion,
            comunasOptions,
        }}>
            {children}
        </ComunaContext.Provider>
    );
};

// HOOK PARA USAR EL CONTEXTO
export const useComuna = () => useContext(ComunaContext);
