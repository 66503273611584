import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ComunaProvider } from './components/ComunaContext';
import Home from "./components/Home";
import ComunaSelected from "./components/ComunaSelected";
import PobrezaView from "./components/PobrezaView";
import SeguridadView from './components/SeguridadView';
import MedioAmbienteView from './components/MedioAmbienteView';
import EducacionView from './components/EducacionView';
import SaludView from './components/SaludView';  // Importamos el componente SaludView
import './styles.css';

const App = () => {

  useEffect(() => {
    const allowedHosts = ['localhost', '192.9.200.123']; // Hosts permitidos

    // Verifica si la aplicación está siendo cargada dentro de un iframe y si el host está permitido
    if (window.self === window.top && !allowedHosts.includes(window.location.hostname)) {
      // Redirige a la URL principal si no está en un iframe y no es uno de los hosts permitidos
      window.location.href = 'https://www.24horas.cl/data/elecciones-municipales';
    }
  }, []);

  return (
    <BrowserRouter>
      <ComunaProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:slug" element={<ComunaSelected/>} />
          <Route path="/:slug/seguridad" element={<SeguridadView/>}/>  
          <Route path="/:slug/pobreza" element={<PobrezaView/>}/> 
          <Route path="/:slug/medio-ambiente" element={<MedioAmbienteView/>}/> 
          <Route path="/:slug/educacion" element={<EducacionView/>}/>  
          <Route path="/:slug/salud" element={<SaludView/>}/>  {/* Nueva ruta para Salud */}
        </Routes>
      </ComunaProvider>
    </BrowserRouter>
  );
};

export default App;
