import React from 'react';
import { 
    Chart as ChartJS, 
    Title, 
    Tooltip, 
    Legend, 
    ArcElement, 
    RadialLinearScale, 
    CategoryScale, 
    LinearScale, 
    BarElement, 
    LineElement 
} from 'chart.js';
import BarChart from './BarChart';
import PieChart from './PieChart';

// Registrar los componentes necesarios de Chart.js
ChartJS.register(
    Title, 
    Tooltip, 
    Legend, 
    ArcElement,       // Para gráficos de torta y círculo
    RadialLinearScale,
    CategoryScale,    // Para gráficos de barras (escala categórica)
    LinearScale,      // Para gráficos con escala lineal
    BarElement,       // Para gráficos de barras
    LineElement       // Para gráficos de línea
);

// Objeto de colores personalizados
const chartColors = {
    verde: '#00b95b',
    amarillo: '#FFB200',
    azul: '#4F76DB',
    naranjo: '#FF9000',
    rojo: '#C71611',
    morado: ['#900086', '#A343A3', '#B775B7', '#CBA7CB', '#DCC3DC', '#E5A9F3', '#8000E3'] // Tonos de morado
};

const ChartWrapper = ({ title, chartType, labels, datasets, color }) => {
    // Aplica los colores personalizados según el color proporcionado
    const colorPalette = chartColors[color] || chartColors.azul; // Usa azul como color por defecto

    // Modificar los datasets para aplicar el color personalizado solo en el caso de los gráficos de barras
    const updatedDatasets = datasets.map(dataset => ({
        ...dataset,
        backgroundColor: Array.isArray(colorPalette) ? colorPalette : colorPalette // En pie charts, aplicamos array de colores
    }));

    switch (chartType) {
        case 'bar':
            return <BarChart title={title} labels={labels} datasets={updatedDatasets} horizontal={false} />;
        case 'horizontalBar':  // Utiliza la misma lógica de BarChart pero con barras horizontales
            return <BarChart title={title} labels={labels} datasets={updatedDatasets} horizontal={true} />;
        case 'pie':
            return <PieChart title={title} labels={labels} datasets={updatedDatasets} color={color} />;
        default:
            return <p>Tipo de gráfico no soportado: {chartType}</p>;
    }
};

export default ChartWrapper;
