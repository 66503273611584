import React from "react"
import iconLink from "../assets/img/icon-link.svg";
import { useComuna } from './ComunaContext';


const HeaderComuna = () => {
    const { comunaName } = useComuna();

    return (
        <section>
            <div className="content-box">
                {/* INFO COMUNA ELEGIDA */}
                <div className="info-comuna">
                    <h3 className="subtitle-comuna-box">Comuna</h3>
                    <h2 className="comuna-elegida" id="select-city">{comunaName}</h2>
                </div>
                {/* GLOSARIO  */}
                <div className="box-glosario">
                    <div className="box-interior-glosario">
                        <div className="icon-box-title"><img src={iconLink} alt="" className="icon-clip" /></div>
                        <a href="https://www.24horas.cl/data/glosario" className="title-glosario" target="_blank">GLOSARIO</a>
                    </div>
                    <div className="box-interior-glosario mg-tp-10">
                        <div className="icon-box-title"><img src={iconLink} alt="" className="icon-clip" /></div>
                        <a href="https://www.24horas.cl/te-sirve/servel/elecciones-2024-conoce-todos-los-candidatos" className="title-glosario" target="_blank">CANDIDATOS</a>
                    </div>
                    <div className="box-interior-glosario mg-tp-10">
                        <div className="icon-box-title"><img src={iconLink} alt="" className="icon-clip" /></div>
                        <a href="https://www.24horas.cl/te-sirve/servel/elecciones-municipales-2024-revisar-con-rut-donde-voto" className="title-glosario" target="_blank">¿Dónde voto?</a>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default HeaderComuna;

