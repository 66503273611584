import React, { useState} from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useComuna } from './ComunaContext';

const Search = ({ className }) => {

    const [selectedOption, setSelectedOption] = useState(null); // GUARDAR OPCION SELECTIONADA
    const { setComunaName, setComunaSlug, comunasOptions } = useComuna();
    const navigate = useNavigate();


    // SELECCIONAR OPCION AL INICIAR -  ACTUALIZA VALOR
    const handleChange = (option) => {
        setSelectedOption(option);
    };

    const handleSearch = async () => {
        if (selectedOption) {
            setComunaName(selectedOption.label);
            setComunaSlug(selectedOption.slug);
            navigate(`/${selectedOption.slug}`);
        }
    };

    // CONFIGURACION ESTILOS DROPDOWN/INPUT
    const customStyles = {
        control: (provided) => ({
            ...provided,
            cursor: 'text',
        }),
    };

    return (
        <div className={className}>
            <Select
                value={selectedOption}
                onChange={handleChange}
                options={comunasOptions}
                placeholder="Busca tu comuna..."
                isClearable
                styles={customStyles}
            />
            <button className="search-btn" type="button" onClick={handleSearch}>Buscar</button>
        </div>
    );
};

export default Search;