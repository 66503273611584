// PieChart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ title, labels, datasets, color, chartWidth, chartHeight }) => {
    // Define los colores por defecto para el gráfico de torta
    const defaultPieColors = ['#ff6384', '#36a2eb', '#ffce56', '#66ff33', '#cc33ff', '#33ccff', '#ff9933'];

    // Si el color es "morado", aplicamos la paleta especial de tonos de morado
    const pieColors = color === 'morado' 
        ? ['#900086', '#A343A3', '#B775B7', '#CBA7CB', '#DCC3DC', '#E5A9F3', '#8000E3'] 
        : defaultPieColors;

    const chartData = {
        labels: labels,
        datasets: datasets.map((dataset) => ({
            ...dataset,
            backgroundColor: pieColors.slice(0, dataset.data.length),
        })),
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function(tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw} armas`;
                    }
                }
            }
        },
        layout: {
            padding: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
            },
        },
    };

    const chartStyle = {
        width: chartWidth || '300px',
        height: chartHeight || '300px',
        margin: '0 auto',
    };

    return (
        <div>
            <h3>{title}</h3>
            <div style={chartStyle}>
                <Pie data={chartData} options={options} />
            </div>
        </div>
    );
};

export default PieChart;
