// SaludView.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import Menu from "./Menu";
import HeaderComuna from "./HeaderComuna";
import { useComuna } from "./ComunaContext";
import DataFetcher from "./DataFetcher";
import FuenteBox from "./Fuente";
import IconComponent from "./IconComponent";
import ComunaHeader from './ComunaHeader';
import ChartWrapper from './charts/ChartWrapper';
import { formatComunaName } from '../utils/utils'; // Asegúrate de importar esta función
import Credits from './Credits';

const SaludView = () => {
    const { slug } = useParams();
    const {
        comunaData,
        fetchComunaData,
        comunaName,
        setComunaName,
        comunaRegion,
        setComunaRegion,
        comunasOptions
    } = useComuna();
    const [loading, setLoading] = useState(true);
    const [prevSlug, setPrevSlug] = useState('');
    const apiUrlSalud = `${process.env.REACT_APP_API_URL}/salud-por-comuna/${slug}`;  // URL de la API para Salud

    useEffect(() => {
        if (slug !== prevSlug) {
            const selectedComuna = comunasOptions.find((c) => c.slug === slug);
            if (selectedComuna) {
                setComunaName(selectedComuna.label);
                setComunaRegion(selectedComuna.region);
                setPrevSlug(slug);
                setLoading(true);
                fetchComunaData(slug).then(() => {
                    setLoading(false);
                });
            }
        }
    }, [slug, prevSlug, setComunaName, setComunaRegion, fetchComunaData, comunasOptions]);

    if (loading || !comunaData) {
        return null;
    }

    return (
        <DataFetcher apiUrl={apiUrlSalud}>
            {({ data: saludData, loading, error }) => {
                if (loading) return null;
                if (error) return <p>Error: {error}</p>;
                if (!saludData) return <p>No hay datos disponibles</p>;

                const {
                    salud,
                    inscritosFonasa,
                    IAAPS2023,
                    IAAPS2023Pais
                } = saludData;

                const { altaComplejidad } = salud;

                // Manejo de altaComplejidad: si no es un número, asignar 0
                const altaComplejidadValue = isNaN(Number(altaComplejidad)) ? 0 : Number(altaComplejidad);

                // Procesar datos de IAAPS2023
                const iaapsComunaStr = IAAPS2023; // Ejemplo: "89,9%"
                const iaapsComuna = Number(iaapsComunaStr.replace(',', '.').replace('%', ''));

                // Mejor cumplimiento
                const mejoresComunas = IAAPS2023Pais.comunaConMejorCumplimiento;

                // Obtener los nombres de las mejores comunas y concatenarlos
                const nombresMejoresComunas = mejoresComunas.map((comuna) => formatComunaName(comuna.nombre));
                const nombresMejoresComunasStr = nombresMejoresComunas.join(', ');

                // Obtener el porcentaje (asumimos que todas tienen el mismo)
                const iaapsMejorStr = mejoresComunas[0].porcentaje; // Ejemplo: "100%"
                const iaapsMejor = Number(iaapsMejorStr.replace(',', '.').replace('%', ''));

                // Peor cumplimiento
                const peorComuna = IAAPS2023Pais.comunaConPeorCumplimiento;
                const iaapsPeorStr = peorComuna.porcentaje; // Ejemplo: "60%"
                const iaapsPeor = Number(iaapsPeorStr.replace(',', '.').replace('%', ''));

                // Crear etiquetas y datos para el gráfico
                const labelComunaActual = `${formatComunaName(comunaName)} ${iaapsComunaStr}`;
                const dataComunaActual = iaapsComuna;

                const labelMejoresComunas = `${nombresMejoresComunasStr} ${iaapsMejorStr}`;
                const dataMejoresComunas = iaapsMejor;

                const labelPeorComuna = `${formatComunaName(peorComuna.nombre)} ${iaapsPeorStr}`;
                const dataPeorComuna = iaapsPeor;

                // Combinar todas las etiquetas y datos
                const labels = [labelComunaActual, labelMejoresComunas, labelPeorComuna];
                const data = [dataComunaActual, dataMejoresComunas, dataPeorComuna];

                return (
                    <div>
                        <HeaderSearch />
                        <HeaderComuna />
                        <Menu />
                        <section id="tema-elegido">
                            <div className="content-box">
                                <ComunaHeader comunaName={comunaName} comunaRegion={comunaRegion} />
                                <div className="info-box">
                                    {/* Sección de la tabla de tipos y centros de salud */}
                                    <div className="info-variable">
                                        <h3 className="title-info-variable">Tipo y centro de salud por comuna</h3>
                                        <div className="grafico-box-principal">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Tipo de Centro de Salud</th>
                                                        <th>Cantidad</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {salud && Object.entries(salud.tipos).map(([tipo, cantidad]) => (
                                                        <tr key={tipo}>
                                                            <td>{tipo}</td>
                                                            <td>{cantidad}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <FuenteBox textoFuente="Base de datos “Establecimientos vigente de salud” en Datos.gob.cl" small />
                                        </div>
                                    </div>
                                    {/* Sección de Inscritos en FONASA */}

                                    <div className="info-variable">
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">Número de inscritos en FONASA</h3>
                                            <IconComponent iconName="icon-inscritos-fonasa" value={inscritosFonasa} />
                                            <FuenteBox textoFuente="Oficio ordinario N°21752/2023 con información de FONASA" small />
                                        </div>

                                        {/* Nueva sección: Número de centros de salud de alta complejidad en la comuna */}
                                        <div className="grafico-box-principal mg-tp-60">
                                            <h3 className="title-info-variable">
                                                Número de centros de salud de alta complejidad en {formatComunaName(comunaName)}
                                            </h3>
                                            <IconComponent iconName="icon-centro-salud" value={altaComplejidadValue} />
                                            <FuenteBox textoFuente="Base de datos “Establecimientos vigente de salud” en Datos.gob.cl" small />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {/* Añadir el nuevo gráfico aquí */}
                                <div className="info-box">
                                    {/* NUEVO GRAFICO */}
                                    <div className="info-variable">
                                        <h3 className="title-info-variable">Comparación de Cumplimiento Metas IAAPS 2023</h3>
                                        <ChartWrapper
                                            chartType="horizontalBar"
                                            labels={labels}
                                            datasets={[{
                                                label: 'Porcentaje de Cumplimiento',
                                                data: data,
                                            }]}
                                            color="verde"
                                            options={{
                                                scales: {
                                                    x: {
                                                        beginAtZero: true,
                                                        max: 100,   
                                                    },
                                                },
                                            }}
                                        />
                                        <FuenteBox
                                            textoFuente={`Las comunas con mejor cumplimiento son: ${nombresMejoresComunasStr} con ${iaapsMejorStr}. La comuna con peor cumplimiento es ${formatComunaName(peorComuna.nombre)} con ${iaapsPeorStr}.`}
                                            small
                                        />
                                    </div>
                                    <div className="info-variable text-start mg-tp-70">
                                        <p className="chart-text" >El IAAPS está compuesto por un conjunto de indicadores que permiten evaluar el funcionamiento integral de la atención primaria de salud. Específicamente consiste en 18 metas que son ponderadas de manera local, es decir cada comuna tiene sus propias exigencias. </p>
                                        <p className="chart-text">Entre las metas se considera por ejemplo la cobertura de vacunación anti-influenza en población objetiva, visita domiciliaria integral,  continuidad de la atención y disponibilidad de fármacos.</p>
                                        <p className="chart-text">El Ministerio de Salud considera que es aceptable cuando una comuna tiene sobre el 90% de cumplimiento en el IAAPS.</p>
                                    </div>
                                </div>
                                <hr />
                                <Credits></Credits>
                            </div>

                        </section>
                    </div>
                );
            }}
        </DataFetcher>
    );
};

export default SaludView;
