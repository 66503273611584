import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import Menu from "./Menu";
import HeaderComuna from "./HeaderComuna";
import { useComuna } from "./ComunaContext";
import DataFetcher from "./DataFetcher";
import FuenteBox from "./Fuente";
import IconComponent from "./IconComponent";
import ComunaHeader from './ComunaHeader';
import ChartWrapper from "./charts/ChartWrapper";
import { formatComunaName } from '../utils/utils'; 
import Credits from './Credits';

const PobrezaView = () => {
    const { slug } = useParams();
    const { comunaData, fetchComunaData, comunaName, setComunaName, comunaRegion, setComunaRegion, comunasOptions } = useComuna();
    const [loading, setLoading] = useState(true);
    const [prevSlug, setPrevSlug] = useState('');
    const apiUrlPobreza = `${process.env.REACT_APP_API_URL}/pobreza-por-comuna/${slug}`;


  // Función para formatear porcentaje (cambia el punto por coma)
  const parsePercentage = (value) => {
    return parseFloat(value.replace(',', '.').replace('%', ''));
};

    useEffect(() => {
        if (slug !== prevSlug) {
            const selectedComuna = comunasOptions.find((c) => c.slug === slug);
            if (selectedComuna) {
                setComunaName(selectedComuna.label);
                setComunaRegion(selectedComuna.region);
                setPrevSlug(slug);
                setLoading(true);
                fetchComunaData(slug).then(() => {
                    setLoading(false);
                });
            }
        }
    }, [slug, prevSlug, setComunaName, setComunaRegion, fetchComunaData, comunasOptions]);

    if (loading || !comunaData) {
        return null;
    }

    return (
        <DataFetcher apiUrl={apiUrlPobreza}>
            {({ data: pobrezaData, loading, error }) => {
                if (loading) return null;
                if (error) return <p>Error: {error}</p>;
                if (!pobrezaData) return <p>No hay datos disponibles</p>;


                const pobrezaComunaPorcentaje = parsePercentage(pobrezaData.pobreza);
                const comunaMasPobrePorcentaje = parsePercentage(pobrezaData.comunaConMasPobreza.porcentaje);
                const comunaMenosPobrePorcentaje = parsePercentage(pobrezaData.comunaConMenosPobreza.porcentaje);

                
                const labels = [
                    `General Lagos (${comunaMasPobrePorcentaje}%)`,
                    `Rio Verde (${comunaMenosPobrePorcentaje}%)`,
                    `${formatComunaName(comunaName)} (${pobrezaComunaPorcentaje}%)`,
                ];
               
                return (
                    <div>
                        <HeaderSearch />
                        <HeaderComuna />
                        <Menu />
                        <section id="tema-elegido">
                            <div className="content-box">
                                <ComunaHeader comunaName={comunaName} comunaRegion={comunaRegion} />
                                <div className="info-box">
                                <div className="info-variable">   
                                 <h3 className="title-info-variable">Pobreza Multidimensional</h3>
                                        <div className="grafico-box-principal">
                                            <ChartWrapper
                                                chartType="horizontalBar"
                                                labels={labels}
                                                datasets={[{
                                                    label: 'Porcentaje de pobreza',
                                                    data: [  comunaMasPobrePorcentaje, comunaMenosPobrePorcentaje, pobrezaComunaPorcentaje, ]
                                                }]}
                                                color="amarillo"
                                            />
                                            <FuenteBox textoFuente="Encuesta Casen 2022. Pobreza multidimensional por comuna: mide un conjunto de cinco dimensiones que buscan identificar si los hogares alcanzan o no un determinado umbral de bienestar. Las dimensiones son: educación, salud, trabajo y seguridad social, vivienda y entorno; y redes y cohesión social." small/>
                                        </div>
                                    </div>
                                    <div className="info-variable">
                                        <h3 className="title-info-variable">Número de Campamentos</h3>
                                        <IconComponent iconName="icon-campamentos" value={pobrezaData.campamentos === "No disponible" ? 0 : pobrezaData.campamentos} />
                                        <p>Nº de campamentos en el país: 1.432</p>
                                        <FuenteBox textoFuente="Catastro de Campamentos 2024, Ministerio de la Vivienda y Urbanismo. Campamentos por comuna: se identifica como campamento a un asentamiento de ocho o más hogares que habitan en posesión irregular de un terreno, con carencia de al menos uno de los tres servicios básicos (electricidad, agua potable y sistema de alcantarillado), cuyas viviendas son precarias, y se encuentran agrupadas y contiguas. " small/>
                                    </div>
                                </div>
                                <hr />
                                <Credits></Credits>
                            </div>
                        </section>
                    </div>
                );
            }}
        </DataFetcher>
    );
};

export default PobrezaView;
