import React, { useEffect, useState } from "react";
import HeaderSearch from "./HeaderSearch";
import Menu from "./Menu";
import HeaderComuna from "./HeaderComuna";
import { useParams } from "react-router-dom";
import { useComuna } from "./ComunaContext";
import FuenteBox from "./Fuente";
import ComunaHeader from './ComunaHeader';
import IconComponent from "./IconComponent";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import Footnote from './Footnote';
import Credits from './Credits';


const ComunaSelected = () => {
    const {
        comunaData,
        fetchComunaData,
        comunaName,
        setComunaName,
        comunaRegion,
        setComunaRegion,
        comunasOptions,
    } = useComuna();
    const { slug } = useParams();
    const [prevSlug, setPrevSlug] = useState(''); // Estado para almacenar el slug anterior

    useEffect(() => {
        // Solo actualizar si el slug cambió
        if (slug !== prevSlug) {
            const selectedComuna = comunasOptions.find((c) => c.slug === slug);
            
            if (selectedComuna) {
                setComunaName(selectedComuna.label);  // Actualizar el nombre de la comuna
                setComunaRegion(selectedComuna.region);  // Actualizar la región
                setPrevSlug(slug); // Actualizar el slug anterior

                // Llamar a la API para obtener los datos
                fetchComunaData(slug);
            }
        }
    }, [slug, prevSlug, setComunaName, setComunaRegion, fetchComunaData, comunasOptions]);

    if (!comunaData) {
        return <LoadingSpinner />;  // Mostrar un mensaje de carga mientras se obtienen los datos
    }

    return (
        <div>
            <HeaderSearch />
            <HeaderComuna />
            <Menu />
            <section id="tema-elegido">
                <div className="content-box" id="perfil-comunal">
                    <ComunaHeader comunaName={comunaName} comunaRegion={comunaRegion} />                                
                    <div className="info-box">
                        <IconComponent
                            iconName="icon-habitantes"
                            value={comunaData.poblacion}
                            subtitle="Nº de habitantes"
                        />
                        <IconComponent
                            iconName="icon-superficie"
                            value={`${comunaData.superficie} km2`}
                            subtitle="Superficie"
                        />
                        <div style={{ display: 'inline-block', position: 'relative' }}>
                            <IconComponent
                                iconName="icon-adulto-mayor"
                                value={`${comunaData.adultomayor}`}
                                subtitle="Población A. Mayor"
                            />
                            <sup className="info-extra-number"><i>(1)</i></sup>
                        </div>
                        <IconComponent
                            iconName="icon-poblacion-rural"
                            value={`${(Math.round(parseFloat(comunaData.poblacionRural.replace(',', '.')) * 10) / 10).toFixed(1)}%`}
                            subtitle="Población Rural"
                        />
                        <div style={{ display: 'inline-block', position: 'relative' }}>
                            <IconComponent
                                iconName="icon-transparencia-activa"
                                value={`${(Math.round(parseFloat(comunaData.transparencia.replace(',', '.')) * 10) / 10).toFixed(1)}%`}
                                subtitle="Transparencia Activa"
                            />
                            <sup className="info-extra-number"> <i>(2)</i></sup>
                        </div>
                    </div>
                    
                    <Footnote
                        number={1}
                        text="Índice de adultos mayores: este indicador expresa la relación entre la cantidad de personas adultas mayores y la cantidad de niños y jóvenes. Se calcula como el cociente entre personas de 65 años y más, respecto de las personas menores de 15 años, multiplicado por 100."
                    />
                    <Footnote
                        number={2}
                        text="Indica el porcentaje de cumplimiento de las normas de Transparencia Activa, que definen por ley lo que cada municipio debe tener publicado para conocimiento ciudadano."
                    />
                    <FuenteBox textoFuente="Censo de Población y Vivienda 2017, Estadísticas territoriales de Biblioteca del Congreso Nacional de Chile, Proyección de población 2024 INE en base a Censo 2017 y Consejo de la Transparencia." />
                    <hr />
                    <Credits></Credits>
                </div>
            </section>
        </div>
    );
};

export default ComunaSelected;
