import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ title, labels, datasets, horizontal }) => {
    const options = {
        indexAxis: horizontal ? 'y' : 'x',  // Si es horizontal, cambia la dirección de las barras
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
        },
        scales: {
            x: {
                beginAtZero: true, // Para empezar desde cero
            },
            y: {
                beginAtZero: true, // Si es horizontal, asegúrate de que las barras empiecen desde 0
            }
        },
    };

    const chartData = {
        labels: labels,
        datasets: datasets.map((dataset) => ({
            ...dataset, // Mantiene los colores personalizados desde ChartWrapper
            backgroundColor: dataset.backgroundColor, // No sobrescribimos el color aquí
            borderColor: dataset.borderColor || 'rgba(75, 192, 192, 1)', // Puedes ajustar el color del borde si lo necesitas
        })),
    };

    return <Bar data={chartData} options={options} />;
};

export default BarChart;
