import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import Menu from "./Menu";
import HeaderComuna from "./HeaderComuna";
import { useComuna } from "./ComunaContext";
import DataFetcher from "./DataFetcher";
import ChartWrapper from "./charts/ChartWrapper";
import FuenteBox from "./Fuente";
import IconComponent from "./IconComponent";
import ComunaHeader from './ComunaHeader';
import { formatComunaName } from '../utils/utils';
import Credits from './Credits';

const SeguridadView = () => {
    const { slug } = useParams();
    const {
        comunaData,
        fetchComunaData,
        comunaName,
        setComunaName,
        comunaRegion,
        setComunaRegion,
        comunasOptions,
    } = useComuna();
    const [loading, setLoading] = useState(true);
    const [prevSlug, setPrevSlug] = useState('');
    const apiUrlSeguridad = `${process.env.REACT_APP_API_URL}/seguridad-por-comuna/${slug}`;

    useEffect(() => {
        if (slug !== prevSlug) {
            const selectedComuna = comunasOptions.find((c) => c.slug === slug);
            if (selectedComuna) {
                setComunaName(selectedComuna.label);
                setComunaRegion(selectedComuna.region);
                setPrevSlug(slug);
                setLoading(true);
                fetchComunaData(slug).then(() => {
                    setLoading(false);
                });
            }
        }
    }, [slug, prevSlug, setComunaName, setComunaRegion, fetchComunaData, comunasOptions]);

    if (loading || !comunaData) {
        return null;
    }

    return (
        <DataFetcher apiUrl={apiUrlSeguridad}>
            {({ data: seguridadData, loading, error }) => {
                if (loading) return null;
                if (error) return <p>Error: {error}</p>;
                if (!seguridadData) return <p>No hay datos disponibles</p>;

                // Validar si delitosViolentos y comparativaRegion existen
                const { delitosViolentos = 0, comparativaRegion = {} } = seguridadData || {};
                const {
                    delitosViolentosMaximoRegion = {},
                    delitosViolentosMinimoRegion = {},
                } = comparativaRegion || {};

                // Normalizar los nombres de las comunas para comparación
                const normalizeString = (str) => str.trim().toLowerCase();

                const comunaNormalized = normalizeString(comunaName);
                const maxComunaNormalized = normalizeString(delitosViolentosMaximoRegion.comuna || '');
                const minComunaNormalized = normalizeString(delitosViolentosMinimoRegion.comuna || '');

                // Preparar datos para el gráfico de delitos violentos
                const labels = [formatComunaName(comunaName)];
                const data = [delitosViolentos];

                if (comunaNormalized !== maxComunaNormalized) {
                    labels.push(formatComunaName(delitosViolentosMaximoRegion.comuna || 'Desconocido'));
                    data.push(delitosViolentosMaximoRegion.tasa || 0);
                }

                if (comunaNormalized !== minComunaNormalized) {
                    labels.push(formatComunaName(delitosViolentosMinimoRegion.comuna || 'Desconocido'));
                    data.push(delitosViolentosMinimoRegion.tasa || 0);
                }

                // Manejo del valor undefined para tasa de homicidios
                const tasaHomicidios = seguridadData.homicidios?.tasaHomicidios || 0;

                const detalles = seguridadData?.armasIncautadas?.detalles || [];
                const labelsArmasIncautadas = detalles.map(detalle => detalle.estadoArmas || 'Sin Estado');
                const armasIncautadas = detalles.map(detalle => detalle.cantidad || 0);;

                console.log("Detalles:", detalles);
                console.log("Labels Armas Incautadas:", labelsArmasIncautadas);
                console.log("Armas Incautadas:", armasIncautadas);

                return (
                    <div>
                        <HeaderSearch />
                        <HeaderComuna />
                        <Menu />
                        <section id="tema-elegido">
                            <div className="content-box">
                                <ComunaHeader comunaName={comunaName} comunaRegion={comunaRegion} />
                                <div className="info-box">
                                    <div className="info-variable">
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">
                                                Tasa de Homicidios por cada 100.000 habitantes
                                            </h3>
                                            <ChartWrapper
                                                chartType="bar"
                                                labels={[
                                                    `${formatComunaName(comunaName)}`,
                                                    'País',
                                                ]}
                                                datasets={[
                                                    {
                                                        label: 'Tasa de Homicidios',
                                                        data: [tasaHomicidios, seguridadData.homicidiosNivelPais || 0],
                                                    },
                                                ]}
                                                color="rojo"
                                            />
                                            <FuenteBox
                                                textoFuente="Informe de Homicidios Consumados 2023, Subsecretaría de Prevención del Delito."
                                                small
                                            />
                                        </div>
                                    </div>
                                    <div className="info-variable">
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">
                                                Tasa de delitos violentos por cada 100.000 habitantes
                                            </h3>
                                            <ChartWrapper
                                                chartType="horizontalBar"
                                                labels={labels}
                                                datasets={[
                                                    {
                                                        label: 'Tasa de Delitos Violentos',
                                                        data: data,
                                                    },
                                                ]}
                                                color="naranjo"
                                            />
                                            <FuenteBox
                                                textoFuente={`*${formatComunaName(
                                                    delitosViolentosMaximoRegion.comuna || 'Desconocido'
                                                )} es la comuna con mayor tasa de delitos violentos, y ${formatComunaName(
                                                    delitosViolentosMinimoRegion.comuna || 'Desconocido'
                                                )} la comuna con menor tasa en la región. Fuente: Centro de estudios y análisis del delito de la Subsecretaría de Prevención del Delito.`}
                                                small
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {detalles.length > 0 && (
                                    <>
                                <div className="info-box">
                                    <div className="info-variable">
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">Armas incautadas por Carabineros 2023</h3>
                                            <IconComponent
                                                iconName="icon-armas"
                                                size="80px"
                                            />
                                            <div className="grafico-box">
                                                <h2 className="title-info">2.211</h2>
                                                <span className="subtitle-info">Total País</span>
                                                <h2 className="title-info">{seguridadData.armasIncautadas.totalArmasIncautadas}</h2>
                                                <span className="subtitle-info">{formatComunaName(comunaName)}</span>
                                            </div>

                                            <FuenteBox
                                                textoFuente="Carabineros de Chile vía ley de transparencia."
                                                small
                                            />
                                        </div>
                                    </div>

                                    <div className="info-variable">
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">Estado armas incautadas</h3>
                                            
                                            <ChartWrapper
                                                chartType="pie"
                                                labels={labelsArmasIncautadas}
                                                datasets={[{
                                                    label: 'Tipos de Armas Inscritas',
                                                    data: armasIncautadas,
                                                }]}
                                                color="morado"
                                                chartWidth="250px"
                                                chartHeight="250px"
                                            />

                                                <p className="chart-text text-start">
                                                    <b>DGMN:</b> armas de fuego inscritas en los registros de la Dirección General de Movilización Nacional.<br></br>
                                                    <b>Serie adulterada:</b> número serial de un arma que se encuentra modificado respecto a su origen.<br></br>
                                                    <b>Serie borrada:</b> arma con su número de serie eliminado.<br></br>
                                                    <b>En peritaje:</b> arma sin número de serie visible o comprobado.<br></br>
                                                    <b>Sin registro DGMN:</b> arma cuya serie no se encuentra registrada.
                                                </p>
                                        
                                            

                                            <FuenteBox
                                                textoFuente="Carabineros de Chile vía ley de transparencia."
                                                small
                                            />
                                        </div>
                                    </div>
                                </div>
                                 <hr />
                                </>
                                 )}
                               
                                <Credits></Credits>
                            </div>
                        </section>
                    </div>
                );
            }}
        </DataFetcher>
    );
};

export default SeguridadView;

