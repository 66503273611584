import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderSearch from "./HeaderSearch";
import Menu from "./Menu";
import HeaderComuna from "./HeaderComuna";
import { useComuna } from "./ComunaContext";
import DataFetcher from "./DataFetcher";
import FuenteBox from "./Fuente";
import IconComponent from "./IconComponent";
import ComunaHeader from './ComunaHeader';
import ChartWrapper from "./charts/ChartWrapper";
import { formatComunaName } from '../utils/utils'; 
import Credits from './Credits';


const EducacionView = () => {
    const { slug } = useParams();
    const { comunaData, fetchComunaData, comunaName, setComunaName, comunaRegion, setComunaRegion, comunasOptions } = useComuna();
    const [loading, setLoading] = useState(true);
    const [prevSlug, setPrevSlug] = useState('');
    const apiUrlEducacion = `${process.env.REACT_APP_API_URL}/educacion-por-comuna/${slug}`;

     // Función para formatear porcentaje (cambia el punto por coma)
     const parsePercentage = (value) => {
        return parseFloat(value.replace(',', '.').replace('%', ''));
    };

    useEffect(() => {
        if (slug !== prevSlug) {
            const selectedComuna = comunasOptions.find((c) => c.slug === slug);
            if (selectedComuna) {
                setComunaName(selectedComuna.label);
                setComunaRegion(selectedComuna.region);
                setPrevSlug(slug);
                setLoading(true);
                fetchComunaData(slug).then(() => {
                    setLoading(false);
                });
            }
        }
    }, [slug, prevSlug, setComunaName, setComunaRegion, fetchComunaData, comunasOptions]);

    if (loading || !comunaData) {
        return null;
    }

    return (
        <DataFetcher apiUrl={apiUrlEducacion}>
            {({ data: educacionData, loading, error }) => {
                if (loading) return null;
                if (error) return <p>Error: {error}</p>;
                if (!educacionData) return <p>No hay datos disponibles</p>;

                // Parsear los porcentajes para que sean números
                const desvinculacionIncidenciaComuna = parsePercentage(educacionData.desvinculacion.tasaIncidencia);
                const desvinculacionIncidenciaGlobal = parsePercentage(educacionData.desvinculacionesGlobal.global.TasaDeIncidencia);
                console.log(educacionData);
                return (
                    <div>
                        <HeaderSearch />
                        <HeaderComuna />
                        <Menu />
                        <section id="tema-elegido">
                            <div className="content-box">
                                <ComunaHeader comunaName={comunaName} comunaRegion={comunaRegion} />
                                <div className="info-box">
                                    <h3 className="title-info-variable">Puntajes Simce 2023</h3>
                                    <div className="info-variable">
                                        <h3 className="title-info-variable">IV básico</h3>
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">Lectura</h3>
                                            <ChartWrapper
                                                chartType="bar"
                                                labels={[
                                                    `${formatComunaName(comunaName)} ${educacionData.puntajesCuartoBasico.puntajeLectura}`,
                                                    `Promedio Nacional ${educacionData.puntajesCtoBasico.promedioNacionalLectura}`
                                                ]}
                                                datasets={[{
                                                    label: 'Puntaje',
                                                    data: [educacionData.puntajesCuartoBasico.puntajeLectura, educacionData.puntajesCtoBasico.promedioNacionalLectura],
                                                }]}
                                            />
                                        </div>
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">Matemática</h3>
                                            <ChartWrapper
                                                chartType="bar"
                                                labels={[
                                                    `${formatComunaName(comunaName)} ${educacionData.puntajesCuartoBasico.puntajeMatematica}`,
                                                    `Promedio Nacional ${educacionData.puntajesCtoBasico.promedioNacionalMatematica}`
                                                ]}
                                                datasets={[{
                                                    label: 'Puntaje',
                                                    data: [educacionData.puntajesCuartoBasico.puntajeMatematica, educacionData.puntajesCtoBasico.promedioNacionalMatematica],
                                                }]}
                                                color="verde"
                                            />
                                            <FuenteBox textoFuente="Agencia de Calidad de la Educación 2023." small/>
                                        </div>
                                    </div>
                                    <div className="info-variable">
                                        <h3 className="title-info-variable">II Medio</h3>
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">Lectura</h3>
                                            <ChartWrapper
                                                chartType="bar"
                                                labels={[
                                                    `${formatComunaName(comunaName)} ${educacionData.puntajes.puntajeLectura}`,
                                                    `Promedio Nacional ${educacionData.puntajesPaes.promedioNacionalLectura}`
                                                ]}
                                                datasets={[{
                                                    label: 'Puntaje',
                                                    data: [educacionData.puntajes.puntajeLectura, educacionData.puntajesPaes.promedioNacionalLectura],
                                                }]}
                                            />
                                        </div>
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">Matemática</h3>
                                            <ChartWrapper
                                                chartType="bar"
                                                labels={[
                                                    `${formatComunaName(comunaName)} ${educacionData.puntajes.puntajeMatematica}`,
                                                    `Promedio Nacional ${educacionData.puntajesPaes.promedioNacionalMatematica}`
                                                ]}
                                                datasets={[{
                                                    label: 'puntaje',
                                                    data: [educacionData.puntajes.puntajeMatematica, educacionData.puntajesPaes.promedioNacionalMatematica],
                                                }]}
                                                color="verde"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="info-box">
                                    <div className="info-variable">
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">Porcentaje De Alumnos De Colegios Municipales Que Obtuvieron Más De 458 Puntos En La PAES</h3>
                                            <IconComponent iconName="icon-paes" value={educacionData.paePorComuna.porcentajeSobre458Puntos} />
                                            <FuenteBox textoFuente="Sistema Nacional de Información Municipal." small/>
                                        </div>
                                    </div>
                                    <div className="info-variable">
                                        <div className="grafico-box-principal">
                                            <h3 className="title-info-variable">Desvinculación escolar</h3>
                                            <ChartWrapper
                                                chartType="horizontalBar"
                                                labels={[
                                                    `${formatComunaName(comunaName)} ${educacionData.desvinculacion.tasaIncidencia}`,
                                                    `Nacional ${educacionData.desvinculacionesGlobal.global.TasaDeIncidencia}`
                                                ]}
                                                datasets={[{
                                                    label: 'Tasa de incidencia',
                                                    data: [desvinculacionIncidenciaComuna, desvinculacionIncidenciaGlobal]
                                                }]}
                                            />
                                            <FuenteBox textoFuente="Subsecretaría de Educación vía solicitud de transparencia." small/>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <Credits></Credits>
                            </div>
                        </section>
                    </div>
                );
            }}
        </DataFetcher>
    );
};

export default EducacionView;
