import React from "react";
import Search from "./Search";
import logo from '../assets/img/chile-elige-2024.svg';
import { Link } from "react-router-dom";

const HeaderSearch = () => (
  <section className="border-btm">
    <div className="content-box">
      <div className="box-logo-interior">
        <Link to="/">
          <img src={logo} alt="Logo Chile Elige 2024" />
        </Link>
      </div>
      <Search className="search-box-interior" />
    </div>
  </section>
);

export default HeaderSearch;
